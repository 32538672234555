// Template used for the Contact-Us page.

import React from "react"
import { Layout, ContentWrapper } from "../components"

import settings from "../../settings"

export default function ContactPage() {

	return (
		<Layout
			title="Contact Us"
		>
			<ContentWrapper>
				<h2>Contact Us</h2>

				<p>For more information and inquiries about any of our products, please feel free to get in touch!</p>

				<address>
					<b>Phone:</b> <a href="tel:334-464-6200">(334) 464-6200</a><br />
					<b>Email:</b> <a href={`mailto:${settings.contactEmail}`}>{settings.contactEmail}</a><br />
					<b>Address:</b><br />
					124 Commerce Drive<br />
					Enterprise, AL 36330
				</address>
			</ContentWrapper>

		</Layout>
	)
}
